import { Route, Routes } from 'react-router-dom';

import { Box, Container, Flex, Heading } from '@chakra-ui/react';
import { NoMatch } from '..';
import MetaHelmet from 'components/MetaHelmet';
import { LocationLayout } from './StandLocationLayout';
import { StandLocationList } from './StandLocationList';
import { Paragraph } from 'theme/ui-components/Typography';

export const StandLocationScreen = () => (
  <>
    <MetaHelmet
      title="Visitor Information Brochure Display Stands"
      description="Visitor Information Brochure Display Stands across Nelson, Marlborough, Christchurch, Canterbury, and the West Coast, New Zealand."
      canonicalUri="stand-locations"
    />
    <Container py={{ base: 12, lg: 20 }}>
      <Flex
        mb={{ base: 6, lg: 8 }}
        sx={{
          '@media print': {
            mb: '0.25em',
          },
        }}
      >
        <Box width={{ lg: '66%' }}>
          <Heading
            as="h1"
            fontSize={{ base: '2xl', lg: '4xl' }}
            mb={4}
            sx={{
              '@media print': {
                fontSize: '24pt',
                mb: 0,
              },
            }}
          >
            Brochure Distribution and Displays location
          </Heading>
          <Paragraph
            fontSize={{ lg: '1rem' }}
            lineHeight={1.618}
            sx={{
              '@media print': {
                display: 'none',
              },
            }}
          >
            Discover our strategically placed brochure display stands in Nelson,
            Marlborough, Christchurch, Canterbury, and the West Coast, New
            Zealand. We're your go-to for distributing tourism materials
            nationwide, featuring prime locations and high-traffic display
            units. Explore our online portal for easy ordering of
            tourism-related products. You'll find us in bustling South Island
            hotels, motels, campgrounds, rental car centers, backpacker
            accommodations, and regional airports
          </Paragraph>
        </Box>
      </Flex>
      <Routes>
        <Route path="/" element={<LocationLayout />}>
          <Route index element={<StandLocationList />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </Container>
  </>
);
