import { formatlink } from '../../helpers';
import { chchAccommodation } from './chchAccommodation';
import { chchHolidayHomes } from './chchHolidayHomes';
import { chchTransport } from './chchTransport';

export interface standing {
  title?: string | undefined;
  region: string;
  category?: string;
  list: string[];
}

const STANDINGS: standing[] = [
  {
    title: 'Nelson',
    region: 'Nelson Airport',
    category: 'Nelson Airport',
    list: [
      'Display stand can be found in arrivals section. To enquire about advertising your brochure at Nelson Airport, please contact richardbenton@me.com',
    ],
  },
  {
    region: 'Nelson/Abel Tasman & Marlborough',
    category: 'Picton, Nelson and Marlborough transport distribution circuit',
    list: [
      'Downtown Car Hire',
      'JJ Airport rentals',
      'Nelson Car Hire Ltd',
      'Omega Rental Cars',
      'Pegasus rental cars',
      'NN Airport Rentals',
      'Apex Rental Cars - Picton',
      'Omega Rental Cars - Picton',
    ],
  },
  {
    region: 'Nelson/Abel Tasman & Marlborough',
    category: 'Nelson City',
    list: [
      'Brook Sanctuary City Office',
      'Balmoral Motel Nelson *',
      'Yogis *',
      'Dabang Coffee *',
      'Two Thumbs Brewery',
      'Sutor Art Gallery *',
      'McCashins',
      'Abelia Motor Lodge',
      'Admirals Motor Inn',
      'Airport Greens Motel',
      'Aloha Lodge',
      'Amber Court Motel',
      'Apex Motor Lodge',
      'Apex Rental Cars Airport',
      'Arrow Motels',
      'Asure Fountain Resort Motel',
      'Beachcomber Motor Inn',
      'Beaches Motor Inn',
      'Beachside Villas Motel - Tahunanui *',
      'Bella Vista Motel',
      'Bridge Backpackers',
      'Brightwater Motor Inn *',
      'Brook Waimarama Sanctuary',
      'Cable Bay Holiday Park',
      'Castles Motel - Tahunanui',
      'Cedar Grove Motor Inn',
      'Century Park Motor Lodge *',
      'Chelsea Park Motel*',
      'Courtesy Court Motel',
      'Delorenzos Motel and Apartments *',
      'Downtown Car Hire *',
      'Driftwood Motel - Tahunanui *',
      'JUMPIN *',
      'Founders Park',
      'Gladstone Motel',
      'Harbourside Motor Lodge',
      'Hoglunds Glass *',
      'Grand Arden Monaco Resort',
      'Kings Gate Motel *',
      'Maitai Valley Motor Camp *',
      'Mapua Wharf NZ Trail Journeys',
      'Milton Chalet Motels',
      // 'Morrison Square Retail Precinct *',
      'Nelson Car Hire Ltd',
      'Nelson City Holiday Park 1',
      'Nelson City Holiday Park 2 *',
      'Nelson English Centre',
      'Nelson Provincial Museum',
      'Nikau Apartments NMIT',
      'Ocean Lodge *',
      'Omega Rental Cars',
      'Palazzo Motor Lodge',
      'Parkside Tahuna Motel',
      'Parkside Motel',
      'Pegasus Rental Cars',
      'Pics Peanut Butter World',
      'Prince Albert Backpackers *',
      'ProKarts Nelson *',
      'Quest Serviced Apartments',
      'Richmond Motel and Holiday Park',
      'Richmond Park Motorhome Camp *',
      'Richmond Queen Street Studios Hotel*',
      'RiverLodge Motel *',
      'Richmond Plains Cellar Door *',
      'Saxton Lodge',
      'Tahuna Beach Holiday Park',
      'Tahuna Beach Motor Camp Kitchen *',
      'Teaology Hardy St. Nelson *',
      'The Honest Lawyer',
      'The Hotel Nelson (1)',
      'The Hotel Nelson (2)',
      'The Rutherford Hotel',
      'The Sails Nelson',
      'The Sands Apartments',
      'The Suter Art Gallery *',
      'Trailways Motor Inn',
      'Tuscany Garden Motor Lodge',
      'Waimarie on Riverside',
      'Y.H.A. Nelson',
      'The Qualitea Café *',
      'Natureland - Tahuna *',
      'Nelson Classic Car Museum *',
      'Fat Tui Café *',
      'MD Outdoors *',
      'Gravity Bicycle Store *',
      'Gentle Cycling Company *',
      'Palms Motel *',
      'JJ Car Rentals',
      'Tasman District Library',
      'Nelson Girls College Domestic Boarders Accommodation',
      'Nelson Girls College International Boarders Accommodation',
      'Halifax Street Café',
      'Rutherford Conference Centre',
      'Arcadia Motel - Tahuna',
    ],
  },
  {
    region: 'Nelson/Abel Tasman & Marlborough',
    category: 'Motueka/Abel Tasman, region',
    list: [
      'Motueka Recreational Centre',
      'Abel Tasman Wilsons',
      'Avalon Court Motel',
      "Eden's Edge",
      'Ginger Dynamite GoGo Food & Coffee *',
      'Kaiteriteri Beach Camp',
      'Kaiteriteri Lodge',
      'Motueka Garden Motel *',
      'Motueka Top Ten (1)',
      'Motueka Top Ten (2)',
      'Nautilus Lodge Motel - Motueka *',
      'Skydive @ Motueka Airport',
      'Trail Journeys NZ - Mapua Wharf',
    ],
  },
  // {
  //   region: 'Nelson/Abel Tasman & Marlborough',
  //   category: 'Takaka',
  //   list: ['Takaka Airport - Air Adventures Reception'],
  // },
  {
    region: 'Nelson/Abel Tasman & Marlborough',
    category: 'Murchison',
    list: [
      'Buller Swingbridge *',
      'Murchison Tea Rooms',
      'Murchison Museum *',
      'Murchison Laundrette *',
      'Grand Suites Murchison *',
      'Mataki Motels *',
    ],
  },
  {
    region: 'Nelson/Abel Tasman & Marlborough',
    category: 'Nelson - Brochures (On Request)',
    list: [
      'Beechwood Café - Murchison',
      'Johanneshof Cellars Koromiko',
      'Kapian International College Auckland',
      'Mapua Leisure Park Mapua',
      'Murchison Visitor Centre.',
      'Hamilton iSite',
      'Nelson Lakes Visitor Centre',
      'Golden Bay Coachlines',
      'NN Airport Rentals',
      "Numerous Air BnB's",
      'Lynton Lodge Nelson',
      'Seifried Estate',
      'The Baywick Inn Nelson',
      'Tasman Bay Backpackers Nelson',
      'Wall Street Accommodation - Kaiteriteri',
      'Gilpin Travel Grey lynn Auckland',
      'Shelbourne Villa, Nelson',
      'JJ Airport Rentals Nelson',
      'Omaka Heights B&B - Blenheim',
      'U Boutique On Haven',
    ],
  },
  {
    region: 'Nelson/Abel Tasman & Marlborough',
    title: 'Marlborough',
    category: 'Picton',
    list: [
      'Apex Rental Cars',
      'Atlantis Backpackers & Motels Picton 1',
      'Atlantis Picton 2',
      'Beachcomber Motor Inn',
      'Gateway Motel *',
      'Harbour View Apartments',
      'Harbour View Motels',
      'Marlborough Airport - Sounds Air',
      "Mikey's Bar & Restaurant *",
      'Omega Rental Cars',
      'Picton Campervan Park',
      'Picton Laundromatt *',
      'Picton Yacht Club Hotel',
      'Piwaka Lodge & Backpackers',
      'Sequoia Backpackers',
      'Sounds Connections',
      'Spring Creek Holiday Park *',
      'Spring Creek Motels *',
      'Tombstone Backpackers',
    ],
  },
  {
    region: 'Nelson/Abel Tasman & Marlborough',
    category: 'Havelock',
    list: ['Havelock Garden Motels *', 'Rutherford Backpackers'],
  },
  {
    region: 'Nelson/Abel Tasman & Marlborough',
    category: 'Blenheim',
    list: [
      'Teaology *',
      'Admirals Motor Lodge',
      'Ashleigh Court Motel',
      'Bings Motel - Blenheim',
      'Blenheim Spa Motor Lodge',
      'Brayshaw Park Vintage Museum',
      'Centre Court Motel',
      'Cherylea Motels *',
      'Criterion Hotel',
      'Ellena Motel *',
      'Grove Park Motor Lodge',
      'Heritage Bakery Café *',
      'Knightsbridge Court Motor Lodge *',
      'Marlborough Vintners Hotel *',
      'Middlepark Motel',
      'Omaka Aviation Heritage Centre',
      'Quality Hotel Marlborough *',
      'Renwick Dairy High Street Renwick *',
      'The Scenic Hotel',
      'Vines Village Café *',
      'Waterfront Motels',
      'Woodbourne Tavern',
    ],
  },
  {
    title: 'West Coast',
    region: 'West Coast',
    category: 'West Coast',
    list: [
      'Hokitika Airport Car Rental',
      'JACKSONS',
      'Jackson Retreat Alpine Holiday Park',
    ],
  },
  {
    region: 'West Coast',
    category: 'Punakaiki',
    list: [
      'Paparoa Park Motel',
      'Scenic Hotel Punakaiki Resort',
      'Punakaiki Rocks Hotel and Garden Bar',
    ],
  },
  {
    region: 'West Coast',
    category: 'Greymouth',
    list: [
      'Bedford Hideaway **',
      'Alpine Rose Motel',
      'Ashley Motor Inn',
      'Coleraine Motel',
      'Duke Hostel',
      'Gables Motor Lodge',
      'Highpark Motor Inn',
      'Montieths Brewery',
      'Kingsgate Hotel - Copthorne Greymouth',
      'Recreation Hotel',
      'Scenicland Motel',
      'Shantytown',
      'Sundowner Motel',
      'The Junction Café',
      'Kumara',
      'Brunnerton Lodge - Taylorville',
      'Bella Vista Greymouth',
      'Challenge Service Station',
    ],
  },
  {
    region: 'West Coast',
    category: 'Haast',
    list: [
      'Spiker Café **',
      'Haast Beach Holiday Park',
      'Haast River Motels & Holiday Park (1)',
      'Haast River Motels & Holiday Park (2)',
      'Haast Heliservices',
      'Heartland World Heritage Hotel (1)',
      'Heartland World Heritage Hotel (2)',
      'Santana Honey Knitwear and Gifts',
      'Haast Food Centre - On the Spot **',
    ],
  },
  {
    region: 'West Coast',
    category: 'Lake Brunner',
    list: ['Lake Brunner Motor Camp', 'Lake Brunner Country Motel'],
  },
  {
    region: 'West Coast',
    category: 'Hokitika',
    list: [
      'The National Kiwi Centre',
      'Lizzys Laudromat',
      'Westland Greenstone',
      'The Mahinapua Hotel',
      'Haka Lodge',
      'Annabelle Motel',
      'Beachfront Hotel (Reception)',
      'Beachfront Hotel (Dining)',
      'Fitzherbert Court Motel',
      'Heritage Highway Motels',
      'Café 39',
      'Hokitika Kiwi Holiday Park(1)',
      'Hokitika Kiwi Holiday Park(2)',
      'Links View Holiday Park',
      'Shining Star',
      'Jade Court Motels',
      'Stumpers Backpackers',
      'West Coast Treetops',
      'BP Hokitika **',
      'Bella Vista Hokitika **',
      'Hokitika Holiday Park (1)',
      'Hokitika Holiday Park (2)',
      'The Kitchen - Hokitika',
    ],
  },
  {
    region: 'West Coast',
    category: 'Ross',
    list: ['Ross Store', 'Ross Beach Top 10 Holiday Park', 'Ross Motels'],
  },
  {
    region: 'West Coast',
    category: 'Harihari',
    list: ['The Rushes Café and Bar', 'Hari Hari Motor Inn'],
  },
  {
    region: 'West Coast',
    category: 'South Westland',
    list: [
      'Alpine Glacier Motel **',
      'Franz Josef Four Square Supermarket',
      'Glacier View Motels',
      'Te Weheka Distinction Fox Glacier',
      '58 On Cron - Franz Josef',
      'Alpine Adventure Centre',
      'Aspen Court Motels',
      'Bella Vista Fox Glacier',
      'Bella Vista Franz Josef',
      'Distinction Fox Glacier Hotel',
      'Fox Glacier Guiding',
      'Fox Glacier Holiday Park 1',
      'Fox Glacier Holiday Park 2',
      'Franz Josef Info Centre',
      'Franz Josef Top 10 (1)',
      'Franz Josef Top 10 (2)',
      'Glen Fern Villas',
      'Glow Worm Accommodation',
      'Heartland Hotel Fox Glacier',
      'Heartland Hotel Glacier Gateway',
      'High Peaks Motor Lodge',
      'Jag Escape - Franz Josef',
      'Lake Matheson Motel',
      'Montrose (1)',
      'Mount Cook View Motels',
      'Punga Grove',
      'Rainforest Retreat',
      'Scenic Hotel Franz Josef 1',
      'Scenic Hotel Franz Josef 2',
      'Skydive Franz - Airport',
      'Sunset Motel',
      'Terrace Motels',
      'The Westhaven Lodge',
      'West Coast Wildlife Centre',
      'Franz Josef YHA',
      'Glacier Highway Motels',
      'Westwood Lodge',
    ],
  },
  {
    region: 'West Coast',
    category: 'Whataroa',
    list: ['White Heron Sanctuary Tours', 'The Lonely Stag Café and Store'],
  },
  {
    region: 'West Coast',
    category: 'Paringa',
    list: ['South Westland Salmon Farm'],
  },
  chchAccommodation,
  chchTransport,
  chchHolidayHomes,

  {
    region: 'Tekapo',
    category: 'Tekapo',
    list: ['Tekapo Springs'],
  },
  // {
  //   title: "",
  //   region: "region",
  //   category: "category",
  //   list: ["location", "location2"],
  // },
];

function filterByRegion(region: string) {
  return STANDINGS.filter(
    (data) => formatlink(data.region) === formatlink(region),
  );
}

let regions = [...new Set(STANDINGS.map((data) => data.region))];

export { regions, STANDINGS, filterByRegion };
