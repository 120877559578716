import { GridItemProps } from '@chakra-ui/react';

export const brochureSize = '152px';
export const brochureTall = '320px';
export interface BrochureItemProps extends GridItemProps {
    name?: string;
    broNumber?: number;
    isDisplayNumber?: boolean;
    openLink?: any;
    imgfile?: string;
    intro?: string;
    phone?: string;
    email?: string;
    address?: string;
    website?: string;
    order?: number;
    forceDisplay?: boolean;
    hideButton?: boolean;
    onAdd?: any;
    dataObject?: any;
    // brochure?: any;
}
