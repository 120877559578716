import SITE_CONFIG from '../../siteConfig';

import { Helmet, HelmetData } from "react-helmet-async";

const helmetData = new HelmetData({});

interface Props {
  title?: string;
  description?: string;
  canonicalUri?: string;
}

export default function MetaHelmet({
  title,
  description,
  canonicalUri,
}: Props) {
  return (
    <Helmet>
      <link
        rel="canonical"
        href={
          canonicalUri
            ? SITE_CONFIG.SITE_URL + `${canonicalUri}`
            : SITE_CONFIG.SITE_URL
        }
      />
      <title>
        {!!title
          ? `${title} | ${SITE_CONFIG.SITE_NAME}`
          : SITE_CONFIG.SITE_NAME}
      </title>
      <meta
        property="og:title"
        content={
          !!title
            ? `${title} | ${SITE_CONFIG.SITE_NAME}`
            : SITE_CONFIG.SITE_NAME
        }
      />
      <meta
        name="description"
        content={description ? description : SITE_CONFIG.SITE_DESCRIPTION}
      />
      <meta
        property="og:description"
        content={description ? description : SITE_CONFIG.SITE_DESCRIPTION}
      />
      <meta
        property="og:url"
        content={
          canonicalUri
            ? SITE_CONFIG.SITE_URL + `${canonicalUri}`
            : SITE_CONFIG.SITE_URL
        }
      />
      <meta property="og:locale" content="en_NZ" />
      {/* TO DO */}

      {/* OG image */}

      {/* <script
               dangerouslySetInnerHTML={{
                  __html: `<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-ZWPL88MTZB"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-ZWPL88MTZB');
</script>`,
               }}
            /> */}
      {/* <script
               type="application/ld+json"
               dangerouslySetInnerHTML={{
                  __html: JSON.stringify(structuredData),
               }}
               key="item-jsonld"
            /> */}
    </Helmet>
  );
}
