const staffData = [
  {
    firstname: 'Richard',
    lastname: 'Benton',
    jobtitle: 'Director',
    phone: '021 669 026',
  },
  // {
  //   firstname: 'Rhonda',
  //   lastname: 'Stewart',
  //   jobtitle: 'Nelson & Marlborough distributer',
  //   phone: '021 980 361',
  //   area: 'Abel Tasman Region / Murchison / Nelson / Picton / Blenheim / Marlborough / Havelock',
  // },
  {
    firstname: 'Juss',
    lastname: 'Robinson',
    jobtitle: 'Nelson & Marlborough distributer',
    phone: '021 0849 2922',
    area: 'Abel Tasman Region / Murchison / Nelson / Picton / Blenheim / Marlborough / Havelock',
  },
  {
    firstname: 'Rose',
    lastname: 'Lennon',
    jobtitle: 'Christchurch distributer',
    phone: '027 222 0921',
    area: 'Arthurs Pass / Christchurch / Canterbury / North Canterbury',
    address:
      'Christchurch & Canterbury Distribution Centre - 220 Geraldine Street, St Albans, Christchurch 8013',
  },
  {
    firstname: 'Jo',
    lastname: 'Dreaver',
    jobtitle: 'Christchurch distributer',
    phone: '021 336 513',
    area: 'Arthurs Pass / Christchurch / Canterbury / North Canterbury',
    // address: 'Christchurch & Canterbury Distribution Centre',
    region: 'Canterbury',
  },
  {
    firstname: 'Lisa',
    lastname: 'Stevenson',
    jobtitle: 'West Coast distributer',
    phone: '027 205 2278',
    area: 'West Coast - Haast, Fox and Franz Josef, Whataroa, Hari Hari, Ross, Hokitika,Greymouth, Punakaiki',
    address:
      'West Coast Wildlife Centre - Cnr Cron & Cowan Streets, Franz Josef 7856',
  },
];

export default staffData;
