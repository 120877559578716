import {
  Button,
  Divider,
  Heading,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { send } from 'emailjs-com';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import SITE_CONFIG from '../../siteConfig';
import { InputElementControl, TextareaControl } from 'ui-form';
import CaptureSchema from 'helpers/formvalidation';

const regionList = [
  'Picton',
  'Blenheim',
  'Nelson',
  'Marlborough',
  'Arthurs Pass',
  'North Canterbury',
  'Canterbury',
  'Christchurch',
  'West Coast',
  'Haast',
  'Fox and Franz Josef',
  'Whataroa',
  'Hokitika',
  'Greymouth',
  'Punakaiki',
  'Others',
];

const ContactForm = () => {
  const [captchaKey, setCaptchaKey] = useState(null);
  const [successfulSubmission, setSuccessfulSubmission] = useState(false);
  const [errorSubmission, setErrorSubmission] = useState(false);
  const submitted = !errorSubmission && !successfulSubmission;
  const errorSubmissionMsg =
    'There was an issue with the submission, please try again or call us: ' +
    SITE_CONFIG.CONTACT_NUMBER;
  // Here's the signature
  const colorMode = useColorModeValue('light', 'dark');
  return (
    <>
      {successfulSubmission ? (
        <Stack>
          <Heading as="h2">Message sent</Heading>
          <Text>{SITE_CONFIG.SUBMITTED_MESSAGE}</Text>
        </Stack>
      ) : (
        <Formik
          initialValues={{
            from_name: '',
            to_name: '',
            from_email: '',
            to_email: SITE_CONFIG.EMAILJS_TO_EMAIL,
            cc_email: '',
            bcc_email: SITE_CONFIG.EMAILJS_BCC,
            region: '',
            subject: '',
            mobilenumber: '',
          }}
          validationSchema={CaptureSchema}
          onSubmit={(values) => {
            // carbon copy
            if (values.region) {
              switch (values.region) {
                case 'Picton':
                case 'Blenheim':
                case 'Nelson':
                case 'Marlborough':
                  values.cc_email = 'angel@nzbrochures.com';
                  values.to_name = 'Angel';
                  break;
                case 'Arthurs Pass':
                case 'North Canterbury':
                case 'Canterbury':
                case 'Christchurch':
                  values.cc_email = 'jo@nzbrochures.com';
                  values.to_name = 'Jo';
                  break;
                case 'West Coast':
                case 'Haast':
                case 'Fox and Franz Josef':
                case 'Whataroa':
                case 'Hokitika':
                case 'Greymouth':
                case 'Punakaiki':
                  values.cc_email = 'lisa@nzbrochures.com';
                  values.to_name = 'Lisa';
                  break;
                default:
                  values.to_name = 'Richard';
              }
            }
            // same shape as initial values
            const templateParams = {
              ...values,
              'g-recaptcha-response': captchaKey,
            };
            // templateParams: Object
            // Template parameters of the template.
            // console.log(templateParams);
            send(
              SITE_CONFIG.EMAILJS_serviceID,
              SITE_CONFIG.EMAILJS_templateID,
              templateParams,
              SITE_CONFIG.EMAILJS_userID,
            )
              .then((response) => {
                setSuccessfulSubmission(true);
                console.log('SUCCESS!', response.status, response.text);
              })
              .catch((err) => {
                setErrorSubmission(true);
                console.log('FAILED...', err);
              });
          }}
        >
          {({
            // values,
            dirty,
            isValid,
            isSubmitting,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Stack spacing={3} alignItems="flex-start">
                <InputElementControl
                  type="text"
                  name="from_name"
                  label="your name"
                  isRequired
                />
                <InputElementControl
                  type="email"
                  name="from_email"
                  label="your email"
                  isRequired
                  // helpText="e.g. name@email.com"
                />
                <InputElementControl
                  name="mobilenumber"
                  type="tel"
                  pattern="[0-9]*"
                  label="mobile number"
                  isRequired
                  // helpText="Click the flag to change the country code"
                />
                <InputElementControl
                  name="region"
                  label="your region"
                  inputType="select"
                >
                  {regionList.map((regionName: any) => (
                    <option key={regionName} value={regionName}>
                      {regionName}
                    </option>
                  ))}
                </InputElementControl>
                <InputElementControl
                  name="subject"
                  type="text"
                  label="subject"
                />
                <TextareaControl
                  name="message"
                  placeholder="Leave us a message *"
                  isRequired
                />
                {errorSubmission && (
                  <Text color="red.400" fontSize="sm" noOfLines={2}>
                    {errorSubmissionMsg}
                  </Text>
                )}
                {!(dirty && isValid && captchaKey !== null) && (
                  <Text color="grey.300" fontSize="sm" noOfLines={2}>
                    ^ Please complete the above fields* in order to submit the
                    form.
                  </Text>
                )}
                <ReCAPTCHA
                  theme={colorMode}
                  sitekey={SITE_CONFIG.RECAPTCHA_SITE_KEY}
                  onChange={setCaptchaKey}
                />
                <Divider />
                <Button
                  w="full"
                  py={5}
                  colorScheme="red"
                  type="submit"
                  isLoading={isSubmitting && submitted}
                  disabled={
                    (!(dirty && isValid) ||
                      isSubmitting ||
                      captchaKey === null) &&
                    submitted
                  }
                >
                  Send enquire
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};
export default ContactForm;
